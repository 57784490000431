export const AUTH_UPDATE = 'AUTH_UPDATE';

export const SEARCH_QUERY_PARAMS_UPDATED = 'SEARCH_QUERY_PARAMS_UPDATED';
export const SEARCH_QUERY_STARTED_RUNNING = 'SEARCH_QUERY_STARTED_RUNNING';
export const SEARCH_QUERY_SUCCESS = 'SEARCH_QUERY_SUCCESS';
export const SEARCH_QUERY_FAILURE = 'SEARCH_QUERY_FAILURE';
export const SEARCH_QUERY_CLEAR_ERR_MSG = 'SEARCH_QUERY_CLEAR_ERR_MSG';

export const HOME_QUERY_PARAMS_UPDATED = 'HOME_QUERY_PARAMS_UPDATED';
export const HOME_QUERY_STARTED_RUNNING = 'HOME_QUERY_STARTED_RUNNING';
export const HOME_QUERY_SUCCESS = 'HOME_QUERY_SUCCESS';
export const HOME_QUERY_FAILURE = 'HOME_QUERY_FAILURE';
export const HOME_QUERY_CLEAR_ERR_MSG = 'HOME_QUERY_CLEAR_ERR_MSG';

export const SUBJECT_QUERY_PARAMS_UPDATED = 'SUBJECT_QUERY_PARAMS_UPDATED';
export const SUBJECT_QUERY_STARTED_RUNNING = 'SUBJECT_QUERY_STARTED_RUNNING';
export const SUBJECT_QUERY_SUCCESS = 'SUBJECT_QUERY_SUCCESS';
export const SUBJECT_QUERY_FAILURE = 'SUBJECT_QUERY_FAILURE';
export const SUBJECT_QUERY_CLEAR_ERR_MSG = 'SUBJECT_QUERY_CLEAR_ERR_MSG';

export const SUBJECT_GDS_QUERY_PARAMS_UPDATED = 'SUBJECT_GDS_QUERY_PARAMS_UPDATED';
export const SUBJECT_GDS_QUERY_STARTED_RUNNING = 'SUBJECT_GDS_QUERY_STARTED_RUNNING';
export const SUBJECT_GDS_QUERY_SUCCESS = 'SUBJECT_GDS_QUERY_SUCCESS';
export const SUBJECT_GDS_QUERY_FAILURE = 'SUBJECT_GDS_QUERY_FAILURE';
export const SUBJECT_GDS_QUERY_CLEAR_ERR_MSG = 'SUBJECT_GDS_QUERY_CLEAR_ERR_MSG';

export const RUN_QUERY_PARAMS_UPDATED = 'RUN_QUERY_PARAMS_UPDATED';
export const RUN_QUERY_STARTED_RUNNING = 'RUN_QUERY_STARTED_RUNNING';
export const RUN_QUERY_SUCCESS = 'RUN_QUERY_SUCCESS';
export const RUN_QUERY_FAILURE = 'RUN_QUERY_FAILURE';
export const RUN_QUERY_CLEAR_ERR_MSG = 'RUN_QUERY_CLEAR_ERR_MSG';

export const RUN_GDS_QUERY_PARAMS_UPDATED = 'RUN_GDS_QUERY_PARAMS_UPDATED';
export const RUN_GDS_QUERY_STARTED_RUNNING = 'RUN_GDS_QUERY_STARTED_RUNNING';
export const RUN_GDS_QUERY_SUCCESS = 'RUN_GDS_QUERY_SUCCESS';
export const RUN_GDS_QUERY_FAILURE = 'RUN_GDS_QUERY_FAILURE';
export const RUN_GDS_QUERY_CLEAR_ERR_MSG = 'RUN_GDS_QUERY_CLEAR_ERR_MSG';

export const RUN_META_QUERY_PARAMS_UPDATED = 'RUN_META_QUERY_PARAMS_UPDATED';
export const RUN_META_QUERY_STARTED_RUNNING = 'RUN_META_QUERY_STARTED_RUNNING';
export const RUN_META_QUERY_SUCCESS = 'RUN_META_QUERY_SUCCESS';
export const RUN_META_QUERY_FAILURE = 'RUN_META_QUERY_FAILURE';
export const RUN_META_QUERY_CLEAR_ERR_MSG = 'RUN_META_QUERY_CLEAR_ERR_MSG';
